<template>
    <div v-if="bookingForm && ready" class="r-style" @contextmenu="handleClick($event)">
      <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900" rel="stylesheet">
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
      <reservation-form-header></reservation-form-header>
      <section>
         <div class="container">
            <h1>{{tr('Booking Information Form')}}</h1>
            <hr>
            <reservation-form-main
                :langCode="langCode"
                :bookingForm.sync="bookingForm"
                :fieldOptions="fieldOptions"
                :invalid="invalid"
            ></reservation-form-main>
         </div>
      </section>
      <section>
         <div class="container">
            <h4>{{tr('PASSENGERS')}}</h4>
            <div  v-for="(pax,i) in bookingForm.BookingFormPaxs">
                <reservation-form-pax :pax.sync="pax" i="i" :langCode="langCode" @uploadPassport="uploadPassport"
                    :datesInvalids="datesInvalids" :invalid="invalid" :requiredFields="requiredFields"
                    :fieldOptions="fieldOptions" :maxSize="maxSize"
                    :i="i"></reservation-form-pax>
            </div>
            <div class="row mt-2">
               <div class="col-12 text-center">
                  <button class="btn btn-primary btn-large" @click="addPax">{{tr('More Passengers')}}</button>
                  <button class="btn btn-primary" @click="removePax" v-if="bookingForm.BookingFormPaxs.length>1">{{tr('Cancel_')}}</button>
               </div>
            </div>
             <h6 v-if="paxMessage" class="mt-4"><i class="text-danger">{{paxMessage}}</i></h6>
         </div>
      </section>
      <section>
         <div class="container">
             <h4>{{tr('INTERNATIONAL FLIGHTS')}}</h4>
             <h6 class="text-center" v-if="departureFlights>1">{{tr('ENTRY FLIGHT')}}</h6>
             <div v-for="(flight,i) in bookingForm.BookingFormFlights" v-if="flight.FlightType=='ENTRY_FLIGHT'">
                <reservation-form-flight :flight.sync="flight" :langCode="langCode" @uploadTicket="uploadTicket"
                    :maxSize="maxSize" :i="i"></reservation-form-flight>
             </div>
         </div>
      </section>
      <section v-if="departureFlights>1">
         <div class="container">
            <h6>{{tr('DEPARTURE FLIGHT')}}</h6>
             <div v-for="(flight,i) in bookingForm.BookingFormFlights" v-if="flight.FlightType=='DEPARTURE_FLIGHT'">
                <reservation-form-flight :flight.sync="flight" :langCode="langCode" @uploadTicket="uploadTicket"
                    :maxSize="maxSize" :i="i"></reservation-form-flight>
             </div>
         </div>
      </section>
      <div class="container">
          <h6 v-if="flightsMessage" class="mt-4 text-center"><i class="text-danger">{{flightsMessage}}</i></h6>
      </div>
      <section>
         <div class="container" >
            <h4>{{tr('DOMESTIC FLIGHTS (COMPLETE ONLY IF YOU HAVE ALREADY PURCHASED INTERNAL FLIGHTS)')}}</h4>
             <div v-for="(flight,i) in bookingForm.BookingFormFlights" v-if="flight.FlightType=='DOMESTIC'">
                <reservation-form-flight :flight.sync="flight" :langCode="langCode" @uploadTicket="uploadTicket"
                    :maxSize="maxSize" :i="i"></reservation-form-flight>
             </div>
            <div class="row mt-2">
               <div class="col-12 text-center">
                  <button class="btn btn-primary" @click="addFlight('DOMESTIC')">{{tr('More Flights')}}</button>
                  <button class="btn btn-primary" @click="removeFlight" v-if="domesticFlights>1">{{tr('Cancel_')}}</button>
               </div>
            </div>
         </div>
      </section>
      <section>
         <div class="container d-flex justify-content-center">
            <div class="row">
                <div class="col-12 text-center font-weight-bold">
                    <label v-if="attached.length>0">{{tr('DOCUMENTS ALREADY SENT')}}:</label>
                    <label v-else>{{tr('NO DOCUMENTS ATTACHED')}}</label>
                </div>
                <div class="col-12 text-center font-weight-bold">
                    {{attached.join(',  ')}}
               </div>
             </div>
         </div>
      </section>
      <section>
         <div class="container d-flex justify-content-center">
            <div class="row">
                <reservation-form-terms :accept.sync="accept" :langCode="langCode">
                </reservation-form-terms>
                <div class="col-12 row mt-2 d-flex justify-content-center alert alert-success" v-if="response">
                    <label>{{response}}</label>
                </div>
                <div class="col-12 row mt-2 d-flex justify-content-center alert alert-success" v-if="response && attachments">
                    <label>{{tr('Sending attachments')}}: {{progress}}/{{attachments}}</label>
                </div>
                <div class="col-12 row mt-2 d-flex justify-content-center alert alert-danger" v-if="responseError">
                    <label>{{responseError}}</label>
                </div>
                <div class="col-12 row mt-2">
                     <button type="button" class="btn btn-send w-100" @click="submit" :disabled="!accept || processing">
                        <span v-if="!processing">{{tr('Send')}}</span>
                        <img class="processing-submit" src="@/img/loading.gif" v-else>
                     </button>
                </div>
                <div class="row mt-2" v-if="errors">
                    <div class="col-md-0">
                        <font-awesome-icon icon="exclamation-triangle" class="fields-error"/>
                    </div>
                    <div class="col-md-10">
                        <label class="fields-error">{{tr('FIELDS_ERRORS')}}</label>
                    </div>
                </div>
            </div>
         </div>
      </section>
      <reservation-form-footer :langCode="langCode" :bookingForm="bookingForm"></reservation-form-footer>
    </div>
</template>

<script>
import Vue from 'vue';

let divInput = Vue.component('div-input', {
  props: ['fieldName', 'label', 'v', 'cols', 'langCode'],
  template: `
       <div class="form-group" :class="'col-md-' + cols">
          <div class="label">{{tr(label)}}
            <span v-if="required">*</span>
          </div>
          <input
            class="form-control"
            type="text"
            :class="(!value && $parent.invalid && required)? 'is-invalid': ''"
            :required="required"
            v-model="value"
            @change="change"
       ></div>
  `,
    data () {
        return {
            value: null,
        }
    },
    mounted () {
        this.value = this.v;
    },
    computed: {
        required () {
            return this.$parent.isRequired(this.fieldName);
        },
    },
    watch: { v () { this.value = this.v;}},
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        change () {
            this.$emit('update:v', this.value);
            this.$emit('change');
        },
    }
})

import '@/css/r-style.scss';
const reservationFormMain = importVueComp('components/reservationform', 'ReservationFormMain');
const reservationFormFlight = importVueComp('components/reservationform', 'ReservationFormFlight');
const reservationFormPax = importVueComp('components/reservationform', 'ReservationFormPax');
const reservationFormHeader = importVueComp('components/reservationform', 'ReservationFormHeader');
const reservationFormFooter = importVueComp('components/reservationform', 'ReservationFormFooter');
const reservationFormTerms = importVueComp('components/reservationform', 'ReservationFormTerms');
import dateSelector from '@/components/tools/DateSelector';
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'reservation-form',
    props: ['id', 'hash', 'langCode', 'paramRequiredFields'],
    components: {
        'reservation-form-main': reservationFormMain,
        'reservation-form-flight': reservationFormFlight,
        'reservation-form-pax': reservationFormPax,
        'reservation-form-header': reservationFormHeader,
        'reservation-form-footer': reservationFormFooter,
        'reservation-form-terms': reservationFormTerms,
        'date-selector': dateSelector,
        'div-input': divInput
    },
    data () {
        return {
            bookingForm: null,
            ready: false,
            users: null,
            fieldOptions: {},
            accept: null,
            serverurl: api.serverEndpoint,
            flightsMessage: null,
            paxMessage: null,
            //paramRequiredFields: null,
            passports: {},
            tickets: {},
            invalid: false,
            endpoint: '/api/bookingform/',
            processing: false,
            errors: false,
            fieldsError: [],
            response: null,
            responseError: null,
            datesInvalids: {},
            attachments: 0,
            attached: [],
            progress: 0,
            maxSize: 3,
            requiredFields: ['PaxName', 'Address', 'Mobile', 'pax.FirstName', 'pax.LastName', 'pax.Email',
                'pax.BirthDate', 'pax.Passport', 'pax.PassportValidTo', 'pax.PassportCountry', 'pax.RoomType'],
        }
    },
    computed:{
        domesticFlights () {
            return _.filter(this.bookingForm.BookingFormFlights, (c) => c.FlightType=='DOMESTIC').length;
        },
        departureFlights () {
            return _.filter(this.bookingForm.BookingFormFlights, (c) => c.FlightType=='DEPARTURE_FLIGHT').length;
        },
    },
    async mounted () {
        await this.onMount();
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        getFilters () {
            let data = {Hash: this.hash, BookingId: parseInt(this.id)};
            return data;
        },
        async onMount () {
            if (this.paramRequiredFields) {
                this.requiredFields = this.paramRequiredFields;
            }
            let data = this.getFilters();
            let record = await api.get(this.endpoint, {filters: JSON.stringify(data)});
            if (record && record.length>0) {
                if (record[0].attachments) this.attached = record[0].attachments;
                let model = api.importMixinModule('bookingform', 'model');
                this.bookingForm = await new model({tableName: 'bookingform', record: record[0], notFieldOptions: true});
                this.bookingForm.original_id = this.bookingForm.id;
                if (this.bookingForm.BookingFormPaxs.length==0){
                    this.bookingForm.addRow({fieldName: 'BookingFormPaxs'});
                }
                let EntryFlight = _.find(this.bookingForm.BookingFormFlights, (c) => c.FlightType=='ENTRY_FLIGHT')
                if (!EntryFlight) {
                    this.bookingForm.addRow({fieldName: 'BookingFormFlights', values: {FlightType: 'ENTRY_FLIGHT'}});

                }
                let DepartureFlight = _.find(this.bookingForm.BookingFormFlights, (c) => c.FlightType=='DEPARTURE_FLIGHT')
                if (!DepartureFlight) {
                    this.bookingForm.addRow({fieldName: 'BookingFormFlights', values: {FlightType: 'DEPARTURE_FLIGHT'}});
                }
                let DomesticFlight = _.find(this.bookingForm.BookingFormFlights, (c) => c.FlightType=='DOMESTIC')
                if (!DomesticFlight) {
                    this.bookingForm.addRow({fieldName: 'BookingFormFlights', values: {FlightType: 'DOMESTIC'}});
                }
                let user = await api.get('/public/api/user/' + this.bookingForm.SalesUserId, {GetAccessGroup: false});
                if (user) {
                    this.fieldOptions.SalesUserId = [{label: user.FirstName, value: user.id}];
                    this.fieldOptions.RoomType = this.getRoomTypesBookingForm();
                }
                this.ready = true;
            }
        },
        addPax () {
            this.bookingForm.addRow({fieldName: 'BookingFormPaxs'});
        },
        removePax () {
            this.bookingForm.removeRow({fieldName: 'BookingFormPaxs', rowNr: this.bookingForm.BookingFormPaxs.length-1});
        },
        addFlight () {
            this.bookingForm.addRow({fieldName: 'BookingFormFlights', values: {FlightType: 'DOMESTIC'}});
        },
        removeFlight () {
            this.bookingForm.removeRow({fieldName: 'BookingFormFlights', rowNr: this.bookingForm.BookingFormFlights.length-1});
        },
        checkFields (self) {
            let res = true;
            self.fieldsError = [];
            for (let fieldName of self.requiredFields) {
                if (fieldName.includes('.')) continue;
                if (!self.bookingForm[fieldName]) {
                    res = false;
                    self.invalid = true;
                    self.fieldsError.push(self.tr(fieldName));
                }
            }
            for (let i in self.bookingForm.BookingFormPaxs) {
                for (let fieldName of self.requiredFields) {
                    if (!fieldName.includes('.')) continue;
                    if (fieldName.split('.')[0]!='pax') continue;
                    let rowFieldName = fieldName.split('.')[1];
                    self.datesInvalids[rowFieldName + '-' + i] = false;
                    if (!self.bookingForm.BookingFormPaxs[i][rowFieldName]) {
                        self.datesInvalids[rowFieldName + '-' + i] = true;
                        self.invalid = true;
                        res = false;
                        let field = _.find(self.bookingForm.BookingFormPaxs[i].fields, (r) => r.name == fieldName.split('.')[1])
                        if (field && field.label && self.fieldsError.indexOf(field.label)) {
                            self.fieldsError.push(self.tr(field.label));
                        }
                    }
                }
            }
            self.fieldsError = Object.assign([], self.fieldsError);
            self.errors = !res;
            return res;
        },
        async saveFile (file) {
            let res = await api.saveAttachments(this.bookingForm.tableName, this.bookingForm.id, [file], (r) => {
                //response.filesError = r;
            });
            if (res) {
                this.attached.push(file.name);
                this.progress += 1;
            }
        },
        async submit () {
            this.response = null;
            this.responseError = null;
            if (!this.checkFields(this)) {
                return;
            }
            this.processing = true;
            let attached = [];
            for (let i in this.passports) {
                if (this.passports[i]) {
                    for (let k in this.passports[i]) {
                        if (this.passports[i][k] && this.passports[i][k].size) {
                            console.log('passports', i , this.passports[i][k].name, this.passports[i][k].size)
                            attached.push(this.passports[i][k]);
                        }
                    }
                }
            }
            for (let flightType in this.tickets) {
                for (let i in this.tickets[flightType]) {
                    if (this.tickets[flightType][i]) {
                        for (let k in this.tickets[flightType][i]) {
                            if (this.tickets[flightType][i][k] && this.tickets[flightType][i][k].size) {
                                console.log('fights', flightType, i , this.tickets[flightType][i][k].name, this.tickets[flightType][i][k].size)
                                attached.push(this.tickets[flightType][i][k]);
                            }
                        }
                    };
                }
            }
            this.attachments = attached.length;
            let rec = Object.assign({}, this.bookingForm);
            tools.clean(rec);
            let r = tools.plainObject(rec);
            let id = this.bookingForm.id? this.bookingForm.id: 0;
            let result = await api.save(`${this.bookingForm.endpoint}${id}`, r, null, this.bookingForm.tableName, (r) => {
                console.log('err', r.err)
                this.responseError = this.tr(r.err);
                this.processing = false;
                api.post('/api/send_logs/', JSON.stringify({logs: console.logs}));
            });
            console.log('result', result)
            if (result) {
                this.progress = 0;
                for (let file of attached) {
                    this.saveFile(file);
                }
                await this.bookingForm.init(result)
                this.processing = false;
                this.response = this.tr('Record saved');
            }
        },
        async uploadPassport (ticket, i) {
            this.passports[i] = ticket;
        },
        async uploadTicket (tickets, i, flightType) {
            if (!this.tickets[flightType]) this.tickets[flightType] = {};
            this.tickets[flightType][i] = tickets;
        },
        isRequired (fieldName) {
            if (this.requiredFields.indexOf(fieldName)>-1) return true;
        },
        getRoomTypesBookingForm () {
            let model = api.importMixinModule('bookingform', 'model');
            return model.roomTypesBookingForm();
        },
        handleClick (event) {
            this.$root.$children[0].handleClick(event);
        }

    },
}
</script>

